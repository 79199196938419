.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.fertilizers {
  display: grid;
  padding: 1rem;
  height: calc(100vh - 2rem - 72px);
  width: calc(100vw - 2rem);
  justify-content: center;
}

.MuiButtonBase-root.MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly {
  padding: 14px 0 0 0!important;
}

.MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected {
  color: #94dcdc !important;
  padding: 0 !important;
}

.MuiBottomNavigationAction-label.Mui-selected {
  font-size: 0.7rem !important;
}

.MuiBottomNavigationAction-label.MuiBottomNavigationAction-iconOnly {
  font-size: 0.7rem !important;
  padding: 0 !important;
}



.MuiBottomNavigation-root {
  width: unset !important;
}

.palitre {
  color: #dee5b6;
  color: #d9bd81;
  color: #d982bb;
  color: #98a3df;
  color: #94dcdc;
  light: #757ce8;
  main: #98a3df;
  dark: #002884;
  contrastText: #98a3df;
  light: #ff7961;
  main: #f44336;
  dark: #ba000d;
  contrastText: #dee5b6;
}
